// import logo from './logo.svg';
// import './App.css';

import Example from "./Modal";
import Banner from "./Banner";
import react, { Component } from "react";
import QrReader from "react-qr-reader";

class App extends Component {
  state = {
    error: null,
    data: null,
    open: false,
    mode: "user",
    lastData: null,
  };

  handleFlip = () => {
    if (this.state.mode == "user"){
      this.setState({
        mode: "environment",
      });
    }else{
      this.setState({
        mode: "user",
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleScan = (data) => {
    if (data) {
      if (this.state.lastData != data) {
        fetch("https://api.nfcnyc.org/v1/checkin?uid="+data,{ 
        method: 'get', 
        headers: new Headers({
          'qr-scanned': true, 
        }), 
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              data: result,
              open: true,
              lastData: data
            });
          },

          (error) => {
            this.setState({
              error: error,
            });
          }
        );
      }
    }
  };

  handleError = (err) => {
    console.error(err);
    this.setState({
      error: err,
    });
  };

  render() {
    const isOpen = this.state.open;
    let modal;
    if (isOpen) {
      modal = <Example data={this.state} onCloseModal={this.handleClose} />;
    }
    return (
      <div>
        {modal}
        <Banner onClick={this.handleFlip}/>
        <div class="h-screen w-screen">
          <QrReader
            delay={2000}
            onError={this.handleError}
            onScan={this.handleScan}
            facingMode = {this.state.mode}
            class="h-screen w-screen"
          />
        </div>
      </div>
    );
  }
}

export default App;
