/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationIcon,
  XCircleIcon,
} from "@heroicons/react/outline";

export default function Example(props) {
  const isOpen = props.data.open;
  const { onCloseModal } = props;

  const [open, setOpen] = useState(isOpen);
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className=" justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-middle overflow-hidden shadow-xl transform transition-all  sm:my-8 sm:align-middle sm:max-w-sm sm:w-full ">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="justify-center">
                  <div className="mt-3 text-center ">
                    {props.data.data.authorized ? (
                      props.data.data.requirePCR ? (
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center w-min h-min rounded-full bg-yellow-50 ">
                          <ExclamationIcon
                            className="h-24 w-24 text-yellow-300"
                            aria-hidden="true"
                          />
                        </div>
                      ) : (
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center w-min h-min rounded-full bg-green-100 ">
                          <CheckCircleIcon
                            className="h-24 w-24 text-green-500"
                            aria-hidden="true"
                          />
                        </div>
                      )
                    ) : (
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center w-min h-min rounded-full bg-red-200 ">
                        <XCircleIcon
                          className="h-24 w-24 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                    <br />

                    <div className="mx-auto flex-shrink-0 flex items-center justify-center  ">
                      <h2 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                        {props.data.data.authorized ? (
                          props.data.data.requirePCR ? (
                            <span class="block text-yellow-300">
                              PCR 확인서를 제출해주세요
                            </span>
                          ) : (
                            <span class="block text-green-500">
                              체크인 되었습니다
                            </span>
                          )
                        ) : (
                          <div>
                            <span class="block text-red-500">
                              유효하지 않습니다
                            </span>
                            <span class="block text-black-500 text-sm">
                              QR 코드가 만료되었거나 제한된 코드입니다.
                            </span>
                          </div>
                        )}
                      </h2>
                    </div>

                    <div className="mx-auto flex-shrink-0 mt-3 flex items-center justify-center">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        <div class="pt-6 text-base leading-6 font-bold sm:text-2xl sm:leading-7">
                          {props.data.data.name}
                        </div>
                      </Dialog.Title>
                    </div>

                    <div className="mx-auto flex-shrink-0 flex items-center justify-center">
                      <div className="mt-2">
                        <p className="text-lg text-gray-500">
                          {props.data.data.group}
                        </p>
                        <p className="text-lg text-gray-500">
                          {props.data.data.expiration}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setOpen(false);
                    onCloseModal();
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
