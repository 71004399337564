import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline'
import {
  RefreshIcon
} from "@heroicons/react/outline";

export default function Banner(props) {
    return (
    <div className="bg-pink-600">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            {/* <span className="flex p-2 rounded-lg bg-pink-800">
              <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </span> */}
            <p className="ml-3 font-bold text-center text-white truncate center">
              <span>NFC 대면예배 체크인</span>
            </p>
          </div>
          {/* <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <a
              href="#"
              className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
            >
              Learn more
            </a>
          </div> */}
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">

              <RefreshIcon onClick={props.onClick} className="h-6 w-6 text-white" aria-hidden="true" />

          </div>
        </div>
      </div>
    </div>
  )
}